import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineClose,
  MdOutlineCheck,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import { Input, Button } from "react-daisyui";
import { useUserStore, UserStore, UserStoreContext } from "../store/UserStore";
import { observer } from "mobx-react";

// const Filter = observer(() => {
//     return (
//         <UserStoreContext.Provider value={new HomecourtStore({
//             editloading:false,
//         })}>
//             <Screen />
//         </UserStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(() => {
  const {
    getUsers,
    setFirst_name,
    setlast_name,
    // setphone_number,
    Countrylist,
    Statelist,
    citylist,
    getCountryList,
    setfiltertrue,
    setemail,
    setgender,
    setcity,
    setstate,
    setcountry,
    setstatus,
    setusertype,
    getFilterUsers,
    setphone_number,
    setDisplay_name,
    NofilterwithPagination,
    setdevicetype,
  } = useUserStore();

  const Reset = () => {
    setFirst_name("");
    setDisplay_name("");
    setlast_name("");
    setphone_number("");
    setemail("");
    setgender("");
    setcity("");
    setstate("");
    setcountry("");
    setstatus("");
    setusertype("");
    setdevicetype("");
    setfiltertrue(false);
  };

  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            NofilterwithPagination(false);
            getFilterUsers(1);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            {/* <div>
                            <label htmlFor="first_name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Display Name</label>
                            <Input
                                name="Display_name"
                                placeholder="Display Name"
                                onChange={(e) => setDisplay_name(e.target.value)}
                                id="Display_name"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div> */}
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name
              </label>
              <Input
                name="first_name"
                placeholder="First Name"
                onChange={(e) => setFirst_name(e.target.value)}
                id="first_name"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            {/* <div>
                            <label htmlFor="last_name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Last Name</label>
                            <Input
                                name="last_name"
                                placeholder="Last Name"
                                onChange={(e) => setlast_name(e.target.value)}
                                id="last_name"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div> */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Email
              </label>
              <Input
                name="email"
                placeholder="Email"
                onChange={(e) => setemail(e.target.value)}
                id="email"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="mobile"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Mobile
              </label>
              <Input
                name="mobile"
                placeholder="Mobile"
                onChange={(e) => setphone_number(e.target.value)}
                id="mobile"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="gender"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Gender
              </label>
              <select
                id="gender"
                defaultValue=""
                onChange={(e) => setgender(e.target.value)}
                name="gender"
                className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            {/* <div>
                            <label htmlFor="Mobile" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Mobile</label>
                            <input
                                name="Mobile"
                                placeholder="Mobile"
                                onChange={(e) => setgender(e.target.value)}
                                id="Mobile"
                                type="number"
                                autoComplete="off"
                                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                            />
                        </div> */}
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country
              </label>
              <select
                id="Country"
                name="Country"
                onChange={(e) => setcountry(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                {Countrylist &&
                  Countrylist.map((res, index) => (
                    <option key={index} value={res?.id} className="">
                      {res?.name}
                    </option>
                  ))}
              </select>
            </div>
            {/* <div>
                            <label
                                htmlFor="state"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                State
                            </label>
                            <select
                                id="state"
                                name="state"
                                onChange={(e) => {setstate(e.target.value);getCityList(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                            >
                                <option value="">All</option>
                                {Statelist && Statelist.map((res,index)=> (
                                    <option key={index} value={res?.id} className="">{res?.name}</option>
                                ))}
                            </select>
                        </div> */}
            {/* <div>
                            <label htmlFor="City" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">City</label>
                            <select
                                id="City"
                                defaultValue=""
                                name="City"
                                onChange={(e) => setcity(e.target.value)}
                                className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                {citylist && citylist.map((res,index)=> (
                                    <option key={index} value={res?.id} className="">{res?.name}</option>
                                ))}

                            </select>
                        </div> */}

            <div>
              <label className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                Device Type
              </label>
              <select
                id="devicetype"
                name="devicetype"
                defaultValue=""
                onChange={(e) => setdevicetype(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="Android">Android</option>
                <option value="Ios">Ios </option>
              </select>
            </div>

            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                id="Status"
                name="Status"
                defaultValue=""
                onChange={(e) => setstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="active">Active</option>
                <option value="in-active">Inactive </option>
                <option value="deleted">Deleted </option>
              </select>
            </div>
            <div>
              <label
                htmlFor="UserType"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                User Type
              </label>
              <select
                id="UserType"
                name="UserType"
                defaultValue=""
                onChange={(e) => setusertype(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="Free">Free</option>
                <option value="Premium">Premium</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                getUsers(1);
                Reset();
              }}
              className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;
