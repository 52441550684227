import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Home/Dashboard";
import Profile from "./Profile/Profile";
import { PageNotFound } from "./PageNotFound/PageNoteFound";
import Setting from "./Setting/Setting";
import UserManagement from "./UserManagement/UserManagement";
import EditUserManagement from "./UserManagement/EditUserManagement";
import ReviewManagement from "./ReviewsManagement/ReviewManagement";
import ReportedUserManagement from "./ReporteUserManagement/ReportedUserManagement";
// import OrderManagement from './OrderManagement/OrderManagement';
// import EditOrderManagement from './OrderManagement/EditOrderManagement';
// import CoupenManagement from './CoupenManagement/CoupenManagement';
// import AddCoupenManagement from './CoupenManagement/AddCoupenManagement/AddCoupenMangament';
// import EditCoupenManagement from './CoupenManagement/EditCoupenManagement/EditCoupenManagement';
import CmsManagement from "./CmsMangement/CmsManagement";
import EditCmsManagement from "./CmsMangement/CmsEdit/CmsEdit";
import AddSetting from "./Setting/AddSetting/AddSettings";
import NotificationList from "./Notifications/NotificationList";
import ContactManagement from "./ContactManagement/ContactList";
import DeletedUser from "./UserManagement/DeletedUser";
import PaymentManagement from "./PaymentManagement/PaymentManagement";
import CategoryManagement from "./CategoryManagement/CategoryManagement";
import AddCategoryManagement from "./CategoryManagement/AddCategoryManagement";
import EditCategoryManagement from "./CategoryManagement/EditCategoryManagement";
import ProductManagement from "./ProductManagement/ProductManagement";
import AddProductManagement from "./ProductManagement/AddProductManagement";
import CategoryProductManagement from "./ProductManagement/CategoryProducts";
import EditProductManagement from "./ProductManagement/EditProductManagement";
import GroupManagement from "./GroupManagement/GroupManagement";
import ChannelManagement from "./ChannelManagement/ChannelManagement";
import SubscriptionManagement from "./SubscriptionManagement/SubscriptionManagement";

export const pages = [
  { path: "/", component: <Dashboard /> },
  { path: "/notifications", component: <NotificationList /> },
  { path: "/profile", component: <Profile /> },
  { path: "/usermanagement", component: <UserManagement /> },
  { path: "/usermanagement/deleteuser", component: <DeletedUser /> },
  { path: "/usermanagement/:id", component: <EditUserManagement /> },
  { path: "/groupmanagement", component: <GroupManagement /> },
  { path: "/contactmanagement", component: <ContactManagement /> },
  { path: "/paymentsmanagement", component: <PaymentManagement /> },
  { path: "/subscriptionsmanagement", component: <SubscriptionManagement /> },
  // {path:"/ordermanagement", component: <OrderManagement/> },
  // {path:"/ordermanagement/:id", component: <EditOrderManagement/> },
  { path: "/reviewsmanagement", component: <ReviewManagement /> },
  { path: "/reporteduser", component: <ReportedUserManagement /> },
  // {path:"/couponmanagement", component: <CoupenManagement/> },
  // {path:"/couponmanagement/addcoupen", component: <AddCoupenManagement/> },
  // {path:"/couponmanagement/:id", component: <EditCoupenManagement/> },
  { path: "/cmsmanagement", component: <CmsManagement /> },
  { path: "/cmsmanagement/:id", component: <EditCmsManagement /> },
  { path: "/setting", component: <Setting /> },
  { path: "/setting/addsetting", component: <AddSetting /> },
  { path: "/categorymanagement", component: <CategoryManagement /> },
  {
    path: "/categorymanagement/addcategory",
    component: <AddCategoryManagement />,
  },
  { path: "/categorymanagement/:id", component: <EditCategoryManagement /> },
  { path: "/productmanagement", component: <ProductManagement /> },
  {
    path: "/productmanagement/category/:id",
    component: <CategoryProductManagement />,
  },
  {
    path: "/productmanagement/addproduct",
    component: <AddProductManagement />,
  },
  { path: "/productmanagement/:id", component: <EditProductManagement /> },
  { path: "/channelmanagement", component: <ChannelManagement /> },
];

const Routess = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      {pages.map(({ component, path }) => (
        <Route key={path} element={component} path={path} />
      ))}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Routess;
