import React from 'react'
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson } from 'react-icons/rx'
import Moment from 'react-moment';
import userimage from "../../assets/images/user.png"
import { observer } from 'mobx-react';
import { HomeStore , HomeStoreContext , useHomeStore } from '../Store/HomeStore';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../UserManagement/store/UserStore';
import { BsBagCheck } from 'react-icons/bs';
import { useState } from 'react';
import { MdReportGmailerrorred } from 'react-icons/md';
const Table = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
  }
  export default Table

  const Screen = observer(()=>{
    const {dashboarddata,loadDashboard,statusChange,getstatusid,setreportedstatus,reportedstatus,ChangeReportedStatus} = useHomeStore()
    const [reportedid,setreportedid] = useState("")
    
  return (
    <>
    <div className="card bg-base-100 shadow-md p-6 mt-8">
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4">Recent New Users</p>
    <div className="card bg-base-100 shadow-md p-6 ">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                {/* <th>Display Name</th> */}
                <th>Name</th>
                <th>Registration Date</th>
                {/* <th>Gender</th> */}
                {/* <th>Country Code</th> */}
                <th>Mobile</th>
                {/* <th>State</th> */}
                {/* <th>City</th> */}
                {/* <th>Country</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadDashboard && 
              <>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
             
            {dashboarddata && dashboarddata?.new_users?.map((data,index)=> (
              <tr key={index}>
              <td>{index+1}</td>
              {/* <td className="font-bold capitalize">{(data?.display_name ??"-")}</td> */}
              <td>
                <div className="flex items-left flex-col flex-wrap">
                  <div>
                    <div className="font-bold capitalize">{(data?.first_name??"-")}</div>
                    {/* <div className="font-bold">Lname</div> */}
                    <div className="capitalize text-xs">{data?.email}</div>
                    </div>
                    <div>
                    {data?.premium === 1 ? (
                            data?.subscription && <>
                              <div className="font-bold capitalize text-[#c312d3]">
                                Premium User
                              </div>
                              <div className="text-sm">
                                Expiry:{" "}
                                {data?.subscription
                                  ?.subscription_expire_date ? (
                                  <Moment format="MM/DD/YYYY">
                                    {
                                      data?.subscription
                                        ?.subscription_expire_date
                                    }
                                  </Moment>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="font-bold capitalize text-[#27d207]">
                              Free User
                            </div>
                          )}
                          </div>
                    </div>
                </td>
                {/* <td className=" capitalize">{data?.gender ?? "-"}</td> */}
                {/* <td>{data?.country_code??"-"}</td> */}
                <td className="capitalize"><Moment format="MM/DD/YYYY">{data?.created_at}</Moment></td>
                <td>{data?.phone_number}</td>
                {/* <td>{data?.state?.name ??"-"}</td> */}
                {/* <td>{data?.city?.name ??"-"}</td> */}
                {/* <td className=" capitalize">{data?.country?.name ??"-"}</td> */}
                <th>
                {data.is_deleted == 1 ?
                    <label
                    title="Change Status"
                    className={`btn capitalize ${ data.is_deleted == 1 && "bg-[red]"}  text-[#000] border-hidden  btn-xs`}
                    onClick={(e) => {
                      getstatusid(data?.id);
                      // getNewstatus(data?.isActive);
                    }}
                  >
                    {data.is_deleted == 1 && "Deleted" 
                    
                    }
                  </label>
                  :
                  <label
                  title="Change Status"
                  htmlFor="my-modal-1"
                  className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                  onClick={(e) => {
                    getstatusid(data?.id);
                    // getNewstatus(data?.isActive);
                  }}
                >
                  {   data.status ? "Active" : "Inactive"  }
                </label>
                  }
                  </th>
                
                {/* <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-1"
                      className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getstatusid(data?.id);
                        // getNewstatus(data?.isActive);
                      }}
                    >
                      {data.status ? "Active" : "Inactive"}
                    </label>
                  </th> */}
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      { data.is_deleted == 0 &&
                         <button title="Edit">
                         <Link to={`/usermanagement/${data?.id}`}>
                             <RxPencil1 />
                         </Link>
                       </button>
                      }
                    </div>
                  </td>
              </tr>
            ))}

            {dashboarddata?.new_users?.length === 0 && 
              <tr>
                <td colSpan={10} className="my-10 text-center">
                  <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No New User Found</p>
                </td>
              </tr>
              }        
            
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-1"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-1"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-1" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
    

   

    {/* Recent Reported Users*/}
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4 mt-8">Recent Reported Users</p>
    <div className="card bg-base-100 shadow-md p-6 ">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Reported By</th>
                <th>Reported For</th>
                <th>Reported Date</th>
                <th>Reported Reason</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadDashboard && 
              <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              {
                dashboarddata && dashboarddata?.recent_reported_users?.map((res,index) => (
                <tr key={index}>
                <td>{index+1}</td>

                <td className=" capitalize"><span className=" font-bold">{res?.report_user?.first_name}</span> <p className="capitalize text-xs">{res?.report_user?.phone_number}</p></td>
                <td className=" capitalize"><span className=" font-bold">{res?.reported_user?.first_name }</span> <p className="capitalize text-xs">{res?.reported_user?.phone_number}</p></td>
                <td className=" capitalize"><Moment format="MM/DD/YYYY">{res.date_time ?? "N/A"}</Moment></td>
                <td className=" capitalize">
                  {res?.report_reason ? <div className="tooltip"> {(`${res?.report_reason}`).substring(0, 50).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res?.report_reason}</span>
                  </div>
                  :
                  "N/A"
                  }
                  
                </td>
                
                <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${res?.is_block === "Unblock" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setreportedid(res?.id);
                        setreportedstatus(res.is_block)
                      }}
                    >
                     {res.is_block !== "Pending" &&"User Is"} {res?.is_block ==="Pending" ? "Pending" : (res?.is_block === "Unblock"? "Activated": "Inactivated" )  }
                    </label>
                  </th>
                  {/* <td></td> */}
                {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/ReportedUsermanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                </tr>
             ))
            }

            {dashboarddata?.recent_reported_users?.length === 0 && 
              <tr>
                <td colSpan={7} className="my-10 text-center">
                  <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Reported User Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-4"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status.
              </h3>
              <div className='mt-3'>
                  {/* <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label> */}
                  <select
                      id="Status"
                      name="Status"
                      required
                      value={reportedstatus}
                      onChange={(e) => setreportedstatus(e.target.value)}
                      className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                      {/* <option value="" className="">All</option> */}
                      <option value="Pending">Pending</option>
                      <option value="Block">Block</option>
                      <option value="Unblock">Unblock</option>
                  </select>
              </div>
              <div className="modal-action justify-center">
              <label
                    onClick={() => {ChangeReportedStatus(reportedid)}}
                    htmlFor="my-modal-4"
                    className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  >
                  Yes
                </label>
                <label htmlFor="my-modal-4" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>


    {/* Recent Purchase */}
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4 mt-8">Recent Purchases</p>
    <div className="card bg-base-100 shadow-md p-6 ">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
            <thead>           
            <tr>
                <th>S.no</th>
                <th>TRANSACTION ID</th>
                <th>ORDER ID</th>
                <th>AMOUNT</th>
                <th>CUSTOMER</th>
                <th>Theme Name</th>
                <th>Payment Method</th>
                <th>ORDER DATE</th>
                <th>PAYMENT STATUS</th>
                
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadDashboard && 
              <>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              {
                dashboarddata && dashboarddata?.recent_purchases?.map((res,index) => (
                  
                <tr key={index}>
                  
                <td>{index+1}</td>

                <td className=" capitalize">#{res?.transaction_id ?? "N/A"}</td>
                <td className=" capitalize">#{res?.order_number ??"N/A"}</td>
                <td className=" capitalize">${res?.order_amount ?? "0"}</td>
                <td className=" capitalize"><span className=" font-bold">{res?.customer?.first_name }</span> <p className="capitalize text-xs">{res?.customer?.email}</p></td>
                <td className=" capitalize">{res?.theme_display_name ?? "N/A"}</td>
                <td className=" capitalize">{res?.payment_method?? "-"}</td>
                <td className=" capitalize"><Moment format="MM/DD/YYYY">{res?.order_date_time ?? "N/A"}</Moment></td>
                <th>
                    <label
                      // title="Change Status"
                      // htmlFor="my-modal-5"
                      className={`btn capitalize ${res?.payment_status === "Completed" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      // onClick={(e) => {
                      // }}
                    >
                      {res?.payment_status}
                    </label>
                  </th>
                  {/* <td></td> */}
                {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/ReportedUsermanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                </tr>
             ))
            }

            {dashboarddata?.recent_purchases?.length === 0 && 
              <tr>
                <td colSpan={9} className="my-10 text-center">
                  <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Recent Purchase Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        </div>
    </div>
    
    {/* Recent Subscription Purchase */}
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4 mt-8">Recent Subscription Purchases</p>
    <div className="card bg-base-100 shadow-md p-6 ">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
            <thead>           
            <tr>
            <th>S.no</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Customer</th>
                <th>Plan</th>
                <th>Payment Method</th>
                <th>Order Date</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadDashboard && 
              <>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              {
                dashboarddata && dashboarddata?.recent_subscriptions?.map((res,index) => (
                  
                <tr key={index}>
                  
                <td>{index+1}</td>

                <td className=" capitalize">#{res?.subscription_id ?? "N/A"}</td>
                <td className=" capitalize">${res?.amount ??"N/A"}</td>
                <td className=" capitalize"><span className=" font-bold">{res?.user?.first_name}</span></td>
                <td className=" capitalize">{res?.payment_cycle ?? "N/A"}</td>
                <td className=" capitalize">{res?.payment_method?? "-"}</td>
                <td className=" capitalize"><Moment format="MM/DD/YYYY">{res?.subscription_start_date ?? "N/A"}</Moment></td>
                </tr>
             ))
            }

            {dashboarddata?.recent_subscriptions?.length === 0 && 
              <tr>
                <td colSpan={9} className="my-10 text-center">
                  <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Recent Subscriptions Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        </div>
    </div>
    </div>
    </>
  )
});

